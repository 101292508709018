import React, { Suspense, lazy } from 'react'
import Table from 'react-bootstrap/Table'
import FormCheck from 'react-bootstrap/FormCheck'
import { MdFlight } from 'react-icons/md'

import { Booking, BookingAddOn } from '../../../types'
import { useCurrentUser } from '../../../common'
import { SimpleLoader } from '../../../components'
import { salmon } from '../../../styles/themeColors'
import { addonMetadata } from '../../../common/constants'
import { ChildrenIcon } from '../../../components/customIcons'

import { orderBookingGuests } from './bookingFunctions'

const AddFlightInfo = lazy(() => import('./AddFlightInfo'))

export const BookingGuestsTable: React.FC<{
  guests?: BookingAddOn[]
  title?: string
  showFlightInfo?: boolean
  booking?: Booking
  hideEmail?: boolean
  hidePlusOne?: boolean
  mainCTA?: React.ReactElement
}> = ({
  title,
  booking,
  guests = [],
  hideEmail = false,
  showFlightInfo = false,
  hidePlusOne = false,
  mainCTA,
}) => {
  const { email } = useCurrentUser()

  if (guests.length <= 0 && !mainCTA) return null

  // in case user forgot to add guests information
  if (guests.length <= 0 && mainCTA) {
    return (
      <div className="mb-2 mt-3 mt-lg-5 d-flex align-items-center">
        <p className="fz-18 fwsb m-0 pr-3">Edit Booking</p>
        {mainCTA}
      </div>
    )
  }

  const orderedGuests = orderBookingGuests({
    guests: guests.filter(el => el.bookingGuest),
    currentUserEmail: email,
  })

  const showFlightInforow = showFlightInfo && booking
  return (
    <>
      <div className="mb-2 mt-3 mt-lg-5 d-flex align-items-center">
        {title && <p className="fz-18 fwsb m-0 pr-3">{title}</p>}
        {mainCTA}
      </div>
      <Table borderless striped>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th className="px-0">{/* icon */}</th>
            {!hideEmail && <th>Email</th>}
            <th>Phone</th>
            {!hidePlusOne && <th>+1</th>}
            {showFlightInforow && <th>Flight Info</th>}
          </tr>
        </thead>
        <tbody>
          {orderedGuests.map(addon => {
            const { id, bookingGuest: guest, offeringAddOn } = addon
            const { kid } = addonMetadata
            return (
              <tr key={`row-${id}`}>
                <td>{guest?.firstName}</td>
                <td>{guest?.lastName}</td>
                <td className="px-0">
                  {offeringAddOn.metadata?.subtype === kid && (
                    <ChildrenIcon color={salmon} />
                  )}
                </td>
                {!hideEmail && <td>{guest?.email}</td>}
                <td>{guest?.phone}</td>
                {!hidePlusOne && (
                  <td>
                    {guest?.plusone === true && (
                      <FormCheck checked custom type="checkbox" readOnly />
                    )}
                  </td>
                )}
                {showFlightInforow && guest?.guest && (
                  <td>
                    <Suspense fallback={<SimpleLoader />}>
                      <AddFlightInfo
                        booking={booking}
                        guestProfile={guest?.guest}
                        ctaClassName="rounded-pill py-0 px-2"
                        customText={<MdFlight size={24} color="white" />}
                        refetchQueries={['getBookingInfo']}
                      />
                    </Suspense>
                  </td>
                )}
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}
